<div class="{{ className }}">
  <label>{{ 'SELECT_ROOM' | translate }}</label>
  <ng-container *ngIf="form && controlName">
    <app-auto-complete
      (selectedItemChange)="onSelectChangeRoom($event)"
      (searchChange)="onSearchChangeRoom($event)"
      [itemsData]="rooms"
      [selectedItem]="form.get(controlName)?.value"
      [minTermLength]="1"
      [disabled]="disabled"
    ></app-auto-complete>
  </ng-container>
</div>
