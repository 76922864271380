<div *ngIf="modal">
  <div class="prompt-header">
    <h4 class="prompt-title">{{ modal.title }}</h4>
  </div>
  <div class="prompt-body" [innerHtml]="modal.body | sanitizeHtml"></div>

  <div class="secondary-confirmation-box" *ngIf="modal.showSecondaryConfirmation">
    <div class="mt-2 mb-2">
      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" [(ngModel)]="checkboxChecked" (change)="handleCheckboxChange()">
        <label class="form-check-label">{{modal.secondaryConfirmationTitle}}</label>
      </div>
    </div>

    <div class="secondary-confirmation-msg-container mb-3" *ngIf="checkboxChecked">
      <div class="icon-container text-danger">
        <i class="ftmi-warning icon"></i>
      </div>
      <div class="secondary-confirmation-msg ml-2 mr-2">
        {{modal.secondaryConfirmationSubTitle}}
      </div>
    </div>
  </div>

  <div class="prompt-footer d-flex justify-content-end">
    <button type="button" class="btn btn-outline-primary btn-sm" (click)="onClose()" *ngIf="!modal.hideCancelButton">{{ modal.secondaryButtonText || 'CANCEL' | translate }}</button>
    <button type="button" class="btn btn-primary btn-sm ml-3" [ngClass]="modal.buttonClass" (click)="onConfirm()">{{ modal.buttonText }}</button>
  </div>
</div>
