<app-select
  [form]="form"
  label="{{ label | translate }}"
  [className]="className"
  [controlName]="controlName"
  [items]="[
    { id: catchall.ByFile, name: 'BY_FILE' | translate },
    { id: catchall.ByRoom, name: 'BY_ROOM' | translate },
    { id: catchall.BySchedule, name: 'BY_SCHEDULE' | translate },
    { id: catchall.ByAutoAssign, name: 'BY_AUTOASSIGN' | translate }
  ]"
>
</app-select>

