<div *ngIf="form" [formGroup]="form">
  <!-- Activity roster -->
   <div *ngIf="isAutoAssignAllowed && form.controls.activityPriority.value.type === activityPriorityType.NormalActivity">
     <div class="cord-label">
       <label class="label">
         {{ 'ACTIVITY_ROSTER' | translate }}
         <span class="heading-count" *ngIf="form.controls.rosterStudents.value?.length > 0">
           {{ form.controls.rosterStudents.value.length }}
         </span>
       </label>
     </div>
     <div class="info-list-message mb-3">
       <div class="mr-1">
         <i class="ftmi-info"></i>
       </div>
       <div class="ml-1 text-left">
         {{'REGISTRATION_IS_OPEN_FOR_ALL_STUDENTS' | translate}}
       </div>
     </div>
   </div>

  <div *ngIf="!isAutoAssignAllowed && this.form.controls.activityPriority.value.type === activityPriorityType.NormalActivity">
    <div class="cord-label">
      <label class="label">
        {{ 'ACTIVITY_ROSTER' | translate }}
      </label>
    </div>
    <div class="empty-list-message">
      {{ 'ATTENDANCE_OPEN_FOR_ALL_STUDENTS' | translate }}
    </div>
  </div>
  <!-- Roster students -->
  <div class="mb-5" *ngIf="form.controls.activityPriority.value.type !== activityPriorityType.NormalActivity || isAutoAssignAllowed">
    <h3>
      {{ 'ROSTER_STUDENTS' | translate }}
      <span class="heading-count" *ngIf="form.controls.rosterStudents.value?.length > 0">
        {{ form.controls.rosterStudents.value.length - form.controls.excludedStudents.value.length }}
        <span class="total"> / {{ form.controls.rosterStudents.value.length }} </span>
      </span>
    </h3>

    <div class="app-input-inline rel">
      <label>
        {{ 'DEFAULT_ROSTER' | translate }}
      </label>
      <div class="edit-roster" *ngIf="this.form.controls.defaultRoster.value">
        <button class="round-btn round-btn-xs" (click)="onEditRoster()" [disabled]="form.disabled" [appTooltip]="'EDIT' | translate">
          <i class="ftmi-edit text-primary"></i>
        </button>
      </div>
      <app-auto-complete-list-by-selection
        (selectedItemChange)="onSelectChangeRoster($event)"
        (searchChange)="onSearchChangeRoster($event)"
        [itemsData]="rosters"
        (clearChange)="onClearChange()"
        [selectedItem]="form.controls.defaultRoster.value"
        [searchOnClick]="true"
        [minTermLength]="0"
      ></app-auto-complete-list-by-selection>
    </div>
    <ul class="list-group list-group-select">
      <li
        *ngFor="let item of form.controls.rosterStudents.value"
        (click)="onUpdateExclusions(item)"
        [class.deselected]="isStudentExcluded(item)"
        class="list-group-item"
      >
        <b class="mr-3">{{ item.user.firstName }} {{ item.user.lastName }}</b>
        {{ item.email }}
        <span *ngIf="!isStudentExcluded(item)" class="ml-auto">
          <span class="round-btn round-btn-xs list-group-action ml-1"
            ><i class="ftmi-check text-primary"></i
          ></span>
        </span>
      </li>
    </ul>
    <div class="empty-list-message" *ngIf="!form.controls.rosterStudents.value.length">
      {{ 'SELECT_A_GROUP_TO_POPULATE_LIST' | translate}}
    </div>
    <app-form-error [control]="form.controls.defaultRoster"></app-form-error>
  </div>
  <!-- Additional students -->
  <div class="mb-5" *ngIf="form.controls.activityPriority.value.type !== activityPriorityType.NormalActivity">
    <h3>
      {{ 'ADDITIONAL_STUDENTS' | translate }}
      <span class="heading-count" *ngIf="form.controls.rosterStudents.value?.length > 0">
        {{ form.controls.additionalStudents.value.length }}
      </span>
    </h3>

    <app-auto-complete-list-group-student [form]="form" (selectChange)="onSelectChangeAdditionalStudent($event)"
      ></app-auto-complete-list-group-student>
  </div>
</div>
