import { Component, DoCheck, Input, isDevMode } from '@angular/core';
import { AbstractControl, AbstractControlDirective } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

/**
 * General component using for printing error for every registered formControl inside of it
 */
@Component({
  selector: 'app-form-error',
  template: `
    <div class="form-error" *ngIf="showError">
      {{ error | async }}
    </div>
  `,
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent implements DoCheck {
  /**
   * All types of error, if we have custom error we must added here to.
   */
  private errorMessages: any = {
    hashTag: (): Observable<string> => this.translate.get('INVALID_HASH_TAG'),
    max: (params: any): Observable<string> => this.translate.get('MAX_VALUE', { value: params.max }),
    min: (params: any): Observable<string> => this.translate.get('MIN_VALUE', { value: params.min }),
    required: (): Observable<string> => this.translate.get('INPUT_REQUIRED'),
    email: (): Observable<string> => this.translate.get('INPUT_INVALID_EMAIL'),
    validateEqualityOfPasswords: (): Observable<string> => this.translate.get('PASSWORDS_NOT_MATCH'),
    validateMaxAttendees: (params: any): Observable<string> =>
      this.translate.get(params.message.text, params.message.interpolateParams),
    validateStartEndTime: (params: any): Observable<string> => this.translate.get(params.message),
    requiredSelectedTimes: (params: any): Observable<string> => this.translate.get(params.message),
    minlength: (params: any): Observable<string> => this.translate.get('MIN_LENGTH', { length: params.requiredLength }),
    maxlength: (params: any): Observable<string> => this.translate.get('MAX_LENGTH', { length: params.requiredLength }),
    password: (): Observable<string> =>
      new Observable(observer => {
        this.translate.get('PASSWORD_SCHEMA').subscribe(name => {
          observer.next(name);
        });
      }),
    isCheck: (params: any): Observable<string> =>
      new Observable(observer => {
        this.translate.get(params.message).subscribe(name => {
          observer.next(name);
        });
      }),
    onlyLetters: (): Observable<string> => this.translate.get('ONLY_LETTERS'),
    onlyPositiveNumbers: (): Observable<string> => this.translate.get('ONLY_POSITIVE_NUMBERS'),
    onlyPositiveIntegerNumbers: (): Observable<string> => this.translate.get('ONLY_POSITIVE_INTEGER_NUMBERS'),
    equalTo: (params: any): Observable<string> =>
      new Observable(observer => {
        this.translate.get(params.message).subscribe(name => {
          observer.next(name);
        });
      }),
    limitFileSize: (params: any): Observable<string> =>
      this.translate.get('MAX_UPLOAD_FILE_SIZE', {
        size: params.fileSizeMb
      }),
    alreadyExists: (params: any): Observable<string> =>
      this.translate.get('ALREADY_EXISTS', {
        name: params.name
      })
  };

  /**
   * Stores and prints all collected error for particular formControl
   */
  public error: Observable<string> | undefined;

  /**
   * Flag indicates any that any of this formControl properties is true(dirty, touched, errors)
   */
  public showError: boolean | undefined;

  @Input()
  private control: AbstractControlDirective | AbstractControl | undefined | null;

  /**
   * Uses TranslateService to show all error messages by selected language
   */
  constructor(private translate: TranslateService) {}

  public ngDoCheck() {
    this.error = this.getErrors();
    this.showError = this.shouldShowErrors();
  }

  /**
   * Returns error message if find it by type in the predefined messages structure
   */
  public getErrors(): Observable<string> | undefined {
    if (!this.control) {
      return;
    }

    return new Observable(observer => {
      if (!this.control) {
        return;
      }

      for (const key in this.control.errors) {
        if (Object.prototype.hasOwnProperty.call(this.control.errors, key)) {
          const field = this.control.errors[key];

          this.getMessage(key, field)?.subscribe((message: string) => {
            observer.next(message);
            observer.complete();
          });
        }
      }
    });
  }

  /**
   * Gets error message based on the type(name) of the chosen validator
   */
  private getMessage(type: string, params: any): Observable<string> | undefined {
    if (!this.errorMessages[type]) {
      if (isDevMode()) {
        console.error('There is no defined error message for validation:' + type);
      }

      return;
    }

    return this.errorMessages[type](params);
  }

  /**
   * Checks all formControl states related with validation and returs the result
   */
  private shouldShowErrors(): boolean {
    if (!this.control) {
      return false;
    }

    return this.control.errors !== null && (this.control.dirty === true || this.control.touched === true);
  }
}
