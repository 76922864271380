<ng-container *ngIf="form">
    <div [formGroup]="form" class="{{ className }}">
      <label for="building">{{ label | translate }}</label>
  
      <ng-select
        placeholder="{{ 'SELECT_LIST' | translate }}"
        [items]="defaultActivityList"
        bindLabel="title"
        bindValue="uuid"
        formControlName="{{ controlName }}"
        (change)="onSelectItem($event)"
      >
      </ng-select>
    </div>
  </ng-container>
  