import { Component, Input,EventEmitter, Output  } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ClassInputType } from '../types/class-input-type';

@Component({
  selector: 'app-default-list-select',
  templateUrl: './default-list-select.component.html',
  styleUrls: ['./default-list-select.component.scss']
})
export class DefaultListSelectComponent {
  @Input() label = 'SELECT_LIST';
  @Input() form: UntypedFormGroup | undefined;
  @Input() controlName = 'defaultList';
  @Input() className: ClassInputType = 'app-input';
  @Input() defaultActivityList: any;
  @Output() itemSelected = new EventEmitter<string>();

  constructor() {}

  onSelectItem(selectedValue: string) {
    console.log(selectedValue)
    this.itemSelected.emit(selectedValue);
  }
}
