import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { QRCodeModule } from 'angularx-qrcode';
import { ActivityPriorityLegendComponent } from './activity-priority-legend/activity-priority-legend.component';
import { AlertMessageComponent } from './alert/alert-message.component';
import { AnnouncementBroadcastComponent } from './announcement-broadcast/announcement-broadcast.component';
import { ConfirmModalTemplateComponent } from './confirmation-modal/confirm-modal-template/confirm-modal-template.component';
import { ConfirmationModalUploadFileComponent } from './confirmation-modal/confirmation-modal-upload-file/confirmation-modal-upload-file.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DirectivesModule } from './directives.module';
import { ExportButtonComponent } from './export-button/export-button.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { DisableClearButtonDirective } from './filter/directives/disable-clear-button.directive';
import { FilterInlineComponent } from './filter/filter-inline/filter-inline.component';
import { FilterStandAloneComponent } from './filter/filter-standalone/filter-standalone.component';
import { FilterComponent } from './filter/filter.component';
import { FlexCalendarAttendanceComponent } from './flex-calendar/flex-calendar-attendance/flex-calendar-attendance.component';
import { FlexCalendarVerticalMoveComponent } from './flex-calendar/flex-calendar-vertical-move/flex-calendar-vertical-move.component';
import { FlexCalendarVerticalComponent } from './flex-calendar/flex-calendar-vertical/flex-calendar-vertical.component';
import { FlexCalendarComponent } from './flex-calendar/flex-calendar.component';
import { FormErrorComponent } from './form-error/form-error.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { PageFooterComponent } from './layouts/page-footer/page-footer.component';
import { PageHeaderComponent } from './layouts/page-header/page-header.component';
import { PreviewFooterComponent } from './layouts/preview-footer/preview-footer.component';
import { PreviewHeaderComponent } from './layouts/preview-header/preview-header.component';
import { PreviewLayoutComponent } from './layouts/preview-layout/preview-layout.component';
import { MenuComponent } from './menu/menu.component';
import { NxgBootstrapModule } from './ngx-bootstrap.module';
import { PipesModule } from './pipes.module';
import { PreloaderComponent } from './preloader/preloader.component';
import { QRCodeGeneratorComponent } from './qrcode-generator/qrcode-generator.component';
import { SegmentComponent } from './segment/segment.component';
import { SelectComponent } from './select/select.component';
import { ActivityTypeSelectComponent } from './shared-extensions/activity-type-select/activity-type-select.component';
import { BuildingSelectComponent } from './shared-extensions/building-select/building-select.component';
import { CatchAllSelectComponent } from './shared-extensions/catch-all-select/catch-all-select.component';
import { FlexDropDownComponent } from './shared-extensions/flex-drop-down/flex-drop-down.component';
import { FlexPeriodSelectComponent } from './shared-extensions/flex-period-select/flex-period-select.component';
import { FutureCheckBoxComponent } from './shared-extensions/future-check-box/future-check-box.component';
import { GraduationYearSelectComponent } from './shared-extensions/graduation-year-select/graduation-year-select.component';
import { PriorityTypeSelectComponent } from './shared-extensions/priority-type-select/priority-type-select.component';
import { RestrictionPeriodSelectComponent } from './shared-extensions/restriction-period-select/restriction-period-select.component';
import { RosterTypeSelectComponent } from './shared-extensions/roster-type-select/roster-type-select.component';
import { ScheduledSelectComponent } from './shared-extensions/scheduled-select/scheduled-select.component';
import { StatusCheckboxComponent } from './shared-extensions/status-checkbox/status-checkbox.component';
import { StatusSelectComponent } from './shared-extensions/status-select/status-select.component';
import { SubstitutePlanSelectComponent } from './shared-extensions/substitute-plan-select/substitute-plan-select.component';
import { TitleSelectComponent } from './shared-extensions/title-select/title-select.component';
import { UserRoleSelectComponent } from './shared-extensions/user-role-select/user-role-select.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { TableActionsComponent } from './table-actions/table-actions.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { SchoolYearSelectorComponent } from './school-year-selector/school-year-selector.component';
import { SchoolSelectComponent } from './shared-extensions/school-select/school-select.component';
import { RedirectionModalComponent } from './redirection-modal/redirection-modal.component';
import { NotificationsBroadcastComponent } from './notifications-broadcast/notifications-broadcast.component';
import { NotificationLinkComponent } from './notification-link/notification-link.component';
import { AutoAssignStatusCheckboxComponent } from './shared-extensions/auto-assign-status-checkbox/auto-assign-status-checkbox.component';
import { DefaultListSelectComponent } from './shared-extensions/default-list-select/default-list-select.component';
@NgModule({
  declarations: [
    FormErrorComponent,
    AlertMessageComponent,
    FileUploaderComponent,
    ConfirmationModalComponent,
    ConfirmModalTemplateComponent,
    ConfirmationModalUploadFileComponent,
    FilterComponent,
    FilterStandAloneComponent,
    FilterInlineComponent,
    PageHeaderComponent,
    TableActionsComponent,
    PreviewHeaderComponent,
    PreviewFooterComponent,
    ActivityPriorityLegendComponent,
    MenuComponent,
    MainLayoutComponent,
    PreviewLayoutComponent,
    SpinnerComponent,
    SelectComponent,
    ScheduledSelectComponent,
    PriorityTypeSelectComponent,
    ActivityTypeSelectComponent,
    PreloaderComponent,
    RosterTypeSelectComponent,
    FlexCalendarComponent,
    FlexCalendarAttendanceComponent,
    FlexCalendarVerticalComponent,
    FlexCalendarVerticalMoveComponent,
    UserRoleSelectComponent,
    GraduationYearSelectComponent,
    CatchAllSelectComponent,
    BuildingSelectComponent,
    StatusSelectComponent,
    TitleSelectComponent,
    SubstitutePlanSelectComponent,
    StatusCheckboxComponent,
    AutoAssignStatusCheckboxComponent,
    FutureCheckBoxComponent,
    PageFooterComponent,
    SegmentComponent,
    FlexPeriodSelectComponent,
    DatePickerComponent,
    RestrictionPeriodSelectComponent,
    FlexDropDownComponent,
    AnnouncementBroadcastComponent,
    ExportButtonComponent,
    DisableClearButtonDirective,
    TimePickerComponent,
    QRCodeGeneratorComponent,
    SchoolYearSelectorComponent,
    SchoolSelectComponent,
    RedirectionModalComponent,
    NotificationsBroadcastComponent,
    NotificationLinkComponent,
    DefaultListSelectComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    PipesModule,
    NxgBootstrapModule,
    TranslateModule.forChild(),
    NgSelectModule,
    QRCodeModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    PipesModule,
    NxgBootstrapModule,
    TranslateModule,
    NgSelectModule,
    NgxDatatableModule,
    FormErrorComponent,
    AlertMessageComponent,
    FileUploaderComponent,
    ConfirmationModalComponent,
    ConfirmModalTemplateComponent,
    ConfirmationModalUploadFileComponent,
    FilterComponent,
    FilterStandAloneComponent,
    FilterInlineComponent,
    PageHeaderComponent,
    TableActionsComponent,
    PreviewHeaderComponent,
    PreviewFooterComponent,
    MenuComponent,
    MainLayoutComponent,
    PreviewLayoutComponent,
    ActivityPriorityLegendComponent,
    SpinnerComponent,
    SelectComponent,
    ScheduledSelectComponent,
    PriorityTypeSelectComponent,
    ActivityTypeSelectComponent,
    PreloaderComponent,
    RosterTypeSelectComponent,
    FlexCalendarComponent,
    FlexCalendarAttendanceComponent,
    FlexCalendarVerticalComponent,
    FlexCalendarVerticalMoveComponent,
    UserRoleSelectComponent,
    GraduationYearSelectComponent,
    CatchAllSelectComponent,
    BuildingSelectComponent,
    StatusSelectComponent,
    TitleSelectComponent,
    SubstitutePlanSelectComponent,
    StatusCheckboxComponent,
    AutoAssignStatusCheckboxComponent,
    FutureCheckBoxComponent,
    PageFooterComponent,
    SegmentComponent,
    FlexPeriodSelectComponent,
    DatePickerComponent,
    RestrictionPeriodSelectComponent,
    FlexDropDownComponent,
    ExportButtonComponent,
    DisableClearButtonDirective,
    TimePickerComponent,
    QRCodeGeneratorComponent,
    QRCodeModule,
    SchoolYearSelectorComponent,
    SchoolSelectComponent,
    RedirectionModalComponent,
    NotificationsBroadcastComponent,
    NotificationLinkComponent,
    DefaultListSelectComponent
  ]
})
export class SharedModule {}
