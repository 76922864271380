import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Authority } from 'src/app/constants/authority';
import { environment } from 'src/environments/environment';
import { AuthFactoryService } from '../../auth/auth-factory.service';
import { Paging } from '../general/classes/paging';
import { RestGenericFileBulkOperations } from '../rest-generic-file-bulk-operations';
import { RestGenericUtilService } from '../rest-generic-util.service';
import { RestGeneric } from '../rest.generic';
import { ResetPassword } from './interfaces/reset-password';
import { SetPassword } from './interfaces/set-password';
import { Student } from './interfaces/student';
import { User } from './interfaces/user';
import { UserRequest } from './interfaces/user-request';
import { UserRoleCount } from './interfaces/user-role-count';
import { UserWithoutGraduationYearCount } from './interfaces/user-without-graduation-year-count';
import { UserStatusCount } from "./interfaces/user-status-count";

@Injectable({
  providedIn: 'root'
})
export class UserService
  extends RestGeneric<UserRequest, User, UserRequest>
  implements RestGenericFileBulkOperations<Student[]>
{
  constructor(
    protected http: HttpClient,
    protected authFactoryService: AuthFactoryService,
    protected restGenericUtilService: RestGenericUtilService
  ) {
    super(http, authFactoryService, 'membership', restGenericUtilService);
  }

  getAllFull(paging = new Paging(), filter?: Record<string, any>): Observable<HttpResponse<User[]>> {
    const params = this.restGenericUtilService.toParams({
      ...paging.getApiPaginationData(),
      ...filter,
      school: this.schoolUUid
    });

    return this.http.get<User[]>(`${this.baseApiUrl}/${this.schoolUUid}/${this.endpointName}`, {
      observe: 'response',
      params
    });
  }

  getEmployeesByRole(paging = new Paging(), filter?: Record<string, any>, authority?: Authority): Observable<User[]> {
    let filterRole: Record<'role[]', Authority>[] | undefined;

    if (!authority) {
      filterRole = [{ 'role[]': Authority.Admin }, { 'role[]': Authority.Staff }, { 'role[]': Authority.Teacher }];
    } else if (authority === Authority.Admin) {
      filterRole = [{ 'role[]': Authority.Admin }];
    } else if (authority === Authority.Staff) {
      filterRole = [{ 'role[]': Authority.Staff }];
    } else if (authority === Authority.Teacher) {
      filterRole = [{ 'role[]': Authority.Teacher }];
    }

    const params = this.restGenericUtilService.toParams({ ...paging.getApiPaginationData(), ...filter });
    const roleParams = this.restGenericUtilService.keyValueArrayToQueryParams(filterRole);

    return this.http.get<User[]>(`${this.baseApiUrl}/${this.schoolUUid}/${this.endpointName}?${roleParams}`, {
      params
    });
  }

  getUsersRoleCount(archivedOnly = false): Observable<UserRoleCount> {
    if (archivedOnly) {
      return this.http.get<UserRoleCount>(
        `${this.baseApiUrl}/${this.schoolUUid}/${this.endpointName}/count?notArchived=false`
      );
    }

    return this.http.get<UserRoleCount>(`${this.baseApiUrl}/${this.schoolUUid}/${this.endpointName}/count`);
  }

  getUsersStatusCount(filter?: Record<string, any>): Observable<UserStatusCount> {
    const params = this.restGenericUtilService.toParams({ ...filter });

    return this.http.get<UserStatusCount>(`${this.baseApiUrl}/${this.schoolUUid}/${this.endpointName}/status/count`, {
      params
    });
  }

  getUsersWithoutGraduationYearCount(): Observable<UserWithoutGraduationYearCount> {
    return this.http.get<UserWithoutGraduationYearCount>(
      `${this.baseApiUrl}/${this.schoolUUid}/${this.endpointName}/without-graduation-year/count`
    );
  }

  patchSetPassword(requestType: SetPassword): Observable<unknown> {
    return this.http.patch<SetPassword>(`${environment.EduSpireApi.domainApiUrl}/sso/user/password`, requestType);
  }

  postResetPassword(requestType: ResetPassword): Observable<unknown> {
    return this.http.post<ResetPassword>(
      `${environment.EduSpireApi.domainSSOUrl}/sso/user/password-reset/initiate`,
      requestType
    );
  }

  postResetPasswordInitiate(requestType: ResetPassword): Observable<unknown> {
    return this.http.post<ResetPassword>(
      `${environment.EduSpireApi.baseUrl}/authentication/user/reset-password-initiate`,
      requestType
    );
  }

  getListByFileHash(fileContentAsSHA256: string): Observable<Student[]> {
    return this.http.get<Student[]>(`${this.baseApiUrl}/${this.schoolUUid}/membership-list/csv/${fileContentAsSHA256}`);
  }

  bulkDelete(uuids: string[]): Observable<void> {
    return this.http.delete<void>(`${this.baseApiUrl}/${this.schoolUUid}/${this.endpointName}/batch`, { body: uuids });
  }

  unarchive(uuid: string) {
    return this.http.post<void>(`${this.baseApiUrl}/${this.endpointName}/${uuid}`, { notArchived: true });
  }

  bulkUnarchive(uuids: string[]): Observable<void> {
    return this.http.patch<void>(`${this.baseApiUrl}/${this.schoolUUid}/${this.endpointName}/batch`, {
      notArchived: true,
      uuidCollection: uuids
    });
  }

  createListFromFileBase64(fileContentAsBase64: string): Observable<Student[]> {
    return this.http.post<Student[]>(`${this.baseApiUrl}/${this.schoolUUid}/membership-list/csv`, {
      contents: fileContentAsBase64
    });
  }

  verifyEmailCode(verificationCode:any): Observable<unknown> {
    return this.http.post<void>(`${environment.EduSpireApi.domainSSOUrl}/sso/verifyEmailCode`, {
      verificationCode: verificationCode
    });
  }

  getAllOwners(): Observable<unknown>{
    return this.http.get<Student[]>(`${this.baseApiUrl}/${this.schoolUUid}/membership-list/csv/`);
  }
}
