import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CatchAll } from 'src/app/core/services/http/general/enums/catchall';
import { ClassInputType } from '../types/class-input-type';

@Component({
  selector: 'app-catch-all-select',
  templateUrl: './catch-all-select.component.html'
})
export class CatchAllSelectComponent {
  @Input() label = 'CATCHALL';
  @Input() form: UntypedFormGroup | undefined;
  @Input() controlName = 'catchall';
  @Input() className: ClassInputType = 'app-input';

  constructor() {}

  catchall = CatchAll;
}
