import { formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppConfig } from 'src/app/configs/app.config';
import { AutoCompleteItem } from 'src/app/shared/auto-complete/interfaces/autocomplete-item';
import { FormGroupService } from 'src/app/shared/form-error/form-group.service';
import { ExtendedDate } from '../../../date-manager/classes/extended-date';
import { DateManagerService } from '../../../date-manager/date-manager.service';
import { FileAdapterService } from '../../file/adapters/file-adapter.service';
import { File } from '../../file/interfaces/file';
import { CatchAll } from '../../general/enums/catchall';
import { Recurrence } from '../../general/enums/recurrence';
import { RoomAdapterService } from '../../room/adapters/room-adapter.service';
import { RosterAdapterService } from '../../roster/adapters/roster-adapter.service';
import { SystemSettings } from '../../system-settings/interfaces/system-settings';
import { UserAdapterService } from '../../user/adapters/user-adapter.service';
import { CatchAllSchedule } from '../interfaces/catchall-schedule';
import { Flex } from '../interfaces/flex';
import { FlexRequest } from '../interfaces/flex-request';

@Injectable({
  providedIn: 'root'
})
export class FlexAdapterService {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private rosterAdapterService: RosterAdapterService,
    private fileAdapterService: FileAdapterService,
    private userAdapterService: UserAdapterService,
    private roomAdapterService: RoomAdapterService,
    private dateManagerService: DateManagerService,
    private formGroupService: FormGroupService
  ) {}

  flexToFlexRequest(flex: Flex): FlexRequest {
    return {
      name: flex.name,
      startDate: flex.startDate,
      endDate: flex.endDate,
      startTime: flex.startTime,
      endTime: flex.endTime,
      cutoffDays: flex.cutoffDays,
      cutoffCountWeekends: flex.cutoffCountWeekends,
      cutoffTime: flex.cutoffTime,
      status: flex.status,
      controlledAccessPeriod: flex.controlledAccessPeriod,
      controlledAccessPeriodRoster: flex.controlledAccessPeriodRoster?.uuid,
      autoAssign:flex.autoAssign
    };
  }

  formGroupToFlexRequest(form: UntypedFormGroup): FlexRequest | undefined {
    if (!form) {
      return;
    }

    const flexRequest: FlexRequest = {
      name: form.controls.name.value,
      startDate: form.controls.startDate.value,
      endDate: form.controls.endDate.value,
      startTime: this.dateManagerService.getSecondsByUTCTime(
        form.controls.startTimeSelector.value,
        form.controls.startDate.value
      ),
      endTime: this.dateManagerService.getSecondsByUTCTime(
        form.controls.endTimeSelector.value,
        form.controls.endDate.value
      ),
      recurrence: this.getRecurrence(form),
      cutoffDays: form.controls.cutoffDays.value,
      cutoffCountWeekends: form.controls.cutOffCountWeekends.value || false,
      cutoffTime: this.dateManagerService.getSecondsByUTCTime(form.controls.cutoffTimeSelector.value),
      enforceRoomCapacity: form.controls.enforceRoomCapacity?.value,
      catchall: form.controls.catchall.value || null,
      controlledAccessPeriod: form.controls.controlledAccessPeriod.value || null,
      lunch: form.controls.lunch.value || null,
      status: form.controls.status.value,
    };

    const controlledAccessPeriod = form.controls.controlledAccessPeriod.value;

    if (controlledAccessPeriod) {
      const controlledAccessPeriodState = form.controls.controlledAccessPeriodState.value;
      flexRequest.controlledAccessPeriod = controlledAccessPeriodState;
      flexRequest.controlledAccessPeriodRoster = (
        form.controls.controlledAccessPeriodRoster?.value as AutoCompleteItem
      )?.value;
    }

    const lunch = form.controls.lunch.value;

    if (lunch) {
      flexRequest.lunchRoom = (form.controls.lunchRoom?.value as AutoCompleteItem)?.value;
      flexRequest.lunchAttendanceCapacity = form.controls.lunchAttendanceCapacity.value;
    }

    const catchall = form.controls.catchall.value;

    if (catchall) {
      const catchallState = form.controls.catchallState.value;
      flexRequest.catchall = catchallState;
      flexRequest.catchallTitle = form.controls.catchallTitle.value;

      if (catchallState === CatchAll.ByFile) {
        flexRequest.autoAssign = false;
        flexRequest.catchallFile = (form.controls.catchallFile?.value as AutoCompleteItem)?.value;
      } else if (catchallState === CatchAll.ByRoom) {
        flexRequest.autoAssign = false;
        flexRequest.catchallRoom = (form.controls.catchallRoom?.value as AutoCompleteItem)?.value;
        flexRequest.catchallByRoomDefaultTeachers = form.controls.catchAllByRoomDefaultTeacherCollection.value?.map(
          (item: AutoCompleteItem) => item.value
        );
      } else if (catchallState === CatchAll.BySchedule) {
        flexRequest.autoAssign = false;
        const catchallSchedules = (form.controls.catchAllSchedules?.value as CatchAllSchedule[])
          .filter(item => item.catchallFile)
          .map(item => {
            return {
              date: item.date,
              catchallFile: (item.catchallFile as AutoCompleteItem<File>).value,
              isPastDate:
                this.dateManagerService.compareDateByDays(
                  this.dateManagerService.getDateBySchoolUtcOffset(),
                  this.dateManagerService.getDateBySchoolUtcOffset(new ExtendedDate(item.date))
                ) === 1
            };
          });

        flexRequest.catchallSchedules = catchallSchedules;
      }else if(catchallState === CatchAll.ByAutoAssign) {
        flexRequest.autoAssign = true;
        flexRequest.autoAssignFile = (form.controls.autoAssignFile?.value as AutoCompleteItem)?.value;
        flexRequest.catchallRoom = (form.controls.catchallRoom?.value as AutoCompleteItem)?.value;
        flexRequest.catchallByRoomDefaultTeachers = form.controls.catchAllByRoomDefaultTeacherCollection.value?.map(
          (item: AutoCompleteItem) => item.value
        );
      }
    }

    return flexRequest;
  }

  flexToFormGroupData(flex: Flex, form?: UntypedFormGroup, systemSettings?: SystemSettings): void {
    if (!form) {
      return;
    }

    const startTime = this.dateManagerService.getTimeByUTCShift(flex.startTime);
    const endTime = this.dateManagerService.getTimeByUTCShift(flex.endTime);
    const cutoffTime = this.dateManagerService.getTimeByUTCShift(flex.cutoffTime);

    this.formGroupService.patchValue(form, {
      uuid: flex.uuid,
      name: flex.name,
      startDate: new ExtendedDate(flex.startDate),
      endDate: new ExtendedDate(flex.endDate),
      startTime: formatDate(startTime, AppConfig.Formats.TimeFormatMidDay, this.locale),
      startTimeSelector: this.dateManagerService.getTimeByUTCShift(flex.startTime),
      endTime: formatDate(endTime, AppConfig.Formats.TimeFormatMidDay, this.locale),
      endTimeSelector: this.dateManagerService.getTimeByUTCShift(flex.endTime),
      cutoffTimeSelector: this.dateManagerService.getTimeByUTCShift(flex.cutoffTime),
      recurrence: flex.recurrence,
      cutoffDays: flex.cutoffDays,
      cutoffTime: formatDate(cutoffTime, AppConfig.Formats.TimeFormatMidDay, this.locale),
      cutOffCountWeekends: flex.cutoffCountWeekends,
      enforceRoomCapacity: flex.enforceRoomCapacity,
      controlledAccessPeriodState: flex.controlledAccessPeriod,
      controlledAccessPeriod: flex.controlledAccessPeriod,
      catchallState: flex.catchall,
      catchall: flex.catchall,
      lunchAttendanceCapacity: flex.lunchAttendanceCapacity,
      lunch: flex.lunch,
      catchallTitle: flex.catchallTitle,
      status: flex.status,
    });

    if (systemSettings) {
      this.systemSettingsToFormGroupData(systemSettings, form);
    }

    if (flex.lunchRoom) {
      const lunchRoom = this.roomAdapterService.roomToAutoCompleteItem([flex.lunchRoom]);

      if (lunchRoom.length === 1) {
        this.formGroupService.setValue(form.controls.lunchRoom, lunchRoom[0]);
      }
    }

    if (flex.catchallRoom) {
      const catchallRoom = this.roomAdapterService.roomToAutoCompleteItem([flex.catchallRoom]);

      if (catchallRoom.length === 1) {
        this.formGroupService.setValue(form.controls.catchallRoom, catchallRoom[0]);
      }
    }

    if (flex.controlledAccessPeriodRoster) {
      const roster = this.rosterAdapterService.rosterToAutoCompleteItem([flex.controlledAccessPeriodRoster]);

      if (roster.length === 1) {
        this.formGroupService.setValue(form.controls.controlledAccessPeriodRoster, roster[0]);
      }
    }

    if (flex.catchallByRoomDefaultTeachers) {
      const users = this.userAdapterService.userToAutoCompleteItem(flex.catchallByRoomDefaultTeachers);

      if (users && users.length > 0) {
        this.formGroupService.setValue(form.controls.catchAllByRoomDefaultTeacherCollection, users);
      }
    }

    if (flex.catchallFile) {
      const file = this.fileAdapterService.uploadFileToAutoCompleteItem([flex.catchallFile]);

      if (file.length === 1) {
        this.formGroupService.setValue(form.controls.catchallFile, file[0]);
      }
    }

    if (flex.autoAssignFile) {
      const file = this.fileAdapterService.uploadFileToAutoCompleteItem([flex.autoAssignFile]);

      if (file.length === 1) {
        this.formGroupService.setValue(form.controls.autoAssignFile, file[0]);
      }
    }

    if (flex.homeroomByScheduleCollection) {
      this.formGroupService.setValue(
        form.controls.catchAllSchedules,
        flex.homeroomByScheduleCollection.map(item => {
          return {
            file: this.fileAdapterService.uploadFileToAutoCompleteItem([item.catchallFile])[0],
            date: item.date
          };
        })
      );
    }

    this.updateRecurrence(form);
  }

  flexToFormGroupCutOffControls(flex: Flex, form?: UntypedFormGroup): void {
    if (!form) {
      return;
    }

    const cutoffTime = this.dateManagerService.getTimeByUTCShift(flex.cutoffTime);

    this.formGroupService.patchValue(form, {
      cutoffDays: flex.cutoffDays,
      cutoffTime: formatDate(cutoffTime, AppConfig.Formats.TimeFormatMidDay, this.locale),
      cutOffCountWeekends: flex.cutoffCountWeekends
    });

    this.formGroupService.disableAbstractControl(
      [form.controls.cutoffDays, form.controls.cutoffTime, form.controls.cutOffCountWeekends],
      false,
      false
    );
  }

  systemSettingsToFormGroupData(systemSettings: SystemSettings, form?: UntypedFormGroup): void {
    if (!form) {
      return;
    }

    if (systemSettings.lunchEnabled && form.controls.lunch.value === true) {
      const lunchCutoffTime = this.dateManagerService.getTimeByUTCShift(systemSettings.lunchCutoffTime);

      this.formGroupService.patchValue(form, {
        cutoffDays: systemSettings.lunchCutoffDays,
        cutoffTime: formatDate(lunchCutoffTime, AppConfig.Formats.TimeFormatMidDay, this.locale),
        cutOffCountWeekends: systemSettings.lunchCutoffCountWeekends
      });

      this.formGroupService.disableAbstractControl([
        form.controls.cutoffDays,
        form.controls.cutoffTime,
        form.controls.cutOffCountWeekends
      ]);
    } else if (form.controls.lunch.value === false) {
      this.formGroupService.disableAbstractControl(
        [form.controls.cutoffDays, form.controls.cutoffTime, form.controls.cutOffCountWeekends],
        false,
        false
      );

      this.formGroupService.clearAbstractControlValue([
        form.controls.cutoffDays,
        form.controls.cutoffTime,
        form.controls.cutOffCountWeekends
      ]);
    }
  }

  getRecurrence(form: UntypedFormGroup): Recurrence[] | undefined {
    const recurrences: Recurrence[] = [];

    if (form.controls?.mon?.value === true) {
      recurrences.push(Recurrence.Monday);
    }

    if (form.controls?.tue?.value === true) {
      recurrences.push(Recurrence.Tuesday);
    }

    if (form.controls?.wed?.value === true) {
      recurrences.push(Recurrence.Wednesday);
    }

    if (form.controls?.thu?.value === true) {
      recurrences.push(Recurrence.Thursday);
    }

    if (form.controls?.fri?.value === true) {
      recurrences.push(Recurrence.Friday);
    }

    return recurrences;
  }

  updateRecurrence(form: UntypedFormGroup): void {
    const recurrences: Recurrence[] = form.controls.recurrence.value;

    if (!recurrences || recurrences?.length === 0) {
      return;
    }

    if (recurrences.includes(Recurrence.Monday)) {
      this.formGroupService.setValue(form.controls.mon, true);
    }

    if (recurrences.includes(Recurrence.Tuesday)) {
      this.formGroupService.setValue(form.controls.tue, true);
    }

    if (recurrences.includes(Recurrence.Wednesday)) {
      this.formGroupService.setValue(form.controls.wed, true);
    }

    if (recurrences.includes(Recurrence.Thursday)) {
      this.formGroupService.setValue(form.controls.thu, true);
    }

    if (recurrences.includes(Recurrence.Friday)) {
      this.formGroupService.setValue(form.controls.fri, true);
    }
  }

  flexToAutoCompleteItem(flexs?: Flex[]): AutoCompleteItem<Flex>[] {
    if (!flexs) {
      return [];
    }

    return flexs.map(item => {
      return {
        value: item.uuid,
        label: `${item.name}`,
        extraLabels: {
          firstLabel: item.name,
          value: item.uuid
        }
      } as AutoCompleteItem<Flex>;
    });
  }
}
