<div class="{{ className }}">
    <label>{{ 'OWNER' | translate }}</label>
    <ng-container *ngIf="form && controlName">
      <app-auto-complete
        (selectedItemChange)="onSelectChangeRoom($event)"
        (searchChange)="onSearchChangeRoom($event)"
        [itemsData]="owners"
        [selectedItem]="form.get(controlName)?.value"
        [minTermLength]="1"
      ></app-auto-complete>
    </ng-container>
  </div>
  